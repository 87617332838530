/*-------------------------
   Brand
-------------------------*/
.brand-wrap-area {
  padding: 90px 0;
}

.brand-grid {
  padding: 10px 0;

  img {
    filter: grayscale(100%);
    opacity: 0.3;
    transition: var(--transition);
  }

  &:hover, &.active {
    img {
      opacity: 1;
      filter: grayscale(0);
    }
  }
}

