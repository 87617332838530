/*-------------------------
    Splash Page Style
-------------------------*/
// Header
.splash-header-style {
  .axil-mainmenu {
    .header-navbar {
      .header-main-nav {
        margin: 0 22px;
      }
    }
  }

  .mainmenu {
    li {
      &.current {
        a {
          color: var(--color-primary);

          &:before {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }

  .buy-btn {
    a {
      display: block;
      @media #{$sm-layout} {
        padding: 15px 30px;
        font-size: 15px;
      }
    }
  }
}

// Banner 
.splash-main-banner {
  background-color: var(--color-light);
  padding: 240px 0;
  height: 1000px;
  z-index: 2;
  @media #{$laptop-device} {
    padding: 200px 0;
  }
  @media #{$smlg-device} {
    height: auto;
    padding: 240px 0 50px;
  }
  @media #{$md-layout} {
    padding: 170px 0 0;
  }
  @media #{$sm-layout} {
    padding: 150px 0 0;
  }

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(250, 184, 196, 0.7) 10%, #FFFFFF 100%);
    clip-path: circle(103% at 50% -52%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    @media #{$laptop-device} {
      clip-path: circle(120% at 50% -52%);
    }
    @media #{$smlg-device} {
      clip-path: circle(140% at 50% -52%);
    }
    @media #{$small-mobile} {
      clip-path: circle(170% at 50% -52%);
    }

  }

  .banner-content {
    text-align: center;

    .title {
      font-size: 70px;
      line-height: 1.4;
      font-weight: 700;
      margin-bottom: 40px;
      letter-spacing: -0.045em;
      @media #{$smlg-device} {
        font-size: 60px;
      }
      @media #{$md-layout} {
        font-size: 50px;
      }
      @media #{$sm-layout} {
        font-size: 40px;
      }

      span {
        color: var(--color-rose);
      }
    }
  }

  .site-element-count {
    display: flex;
    align-items: center;
    justify-content: center;

    .count-box {
      margin-right: 55px;
      text-align: center;
      min-width: 170px;

      &:last-child {
        margin-right: 0;
      }

      @media #{$sm-layout} {
        min-width: 80px;
        margin-right: 30px;
      }
    }

    .count-title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
      font-family: var(--font-primary);
    }

    .count-number {
      font-size: 80px;
      color: var(--color-primary);
      font-weight: 600;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media #{$smlg-device} {
        font-size: 70px;
      }
      @media #{$md-layout} {
        font-size: 50px;
      }
      @media #{$large-mobile} {
        font-size: 40px;
      }
    }
  }

  .demo-slider {
    margin-top: 60px;
    @media #{$sm-layout} {
      margin-top: 30px;
    }

    .slick-slide {
      transform: scale(0.85);
      margin: 0 0 100px;
      transition: var(--transition);
      @media #{$md-layout} {
        margin: 0 0 100px;
      }
      @media #{$sm-layout} {
        margin: 0 0 60px;
      }

      a {
        border-radius: 30px;
        box-shadow: 0 60px 86px -30px rgba(153, 159, 174, .4);

        img {
          border-radius: 30px;
        }
      }

      &.slick-active.slick-center {
        transform: scale(1);
      }
    }

    .slick-dots {
      list-style: none;
      text-align: center;
      top: -40px;
      position: relative;

      li {
        display: inline-block;

        button {
          font-size: 0;
          padding: 0;
        }
      }

    }
  }
}

// Main Demo
.main-demo-area {
  padding: 380px 0 120px;
  @media #{$laptop-device} {
    padding: 300px 0 90px;
  }
  @media #{$smlg-device} {
    padding: 0 0 80px;
  }

  .section-heading {
    margin-bottom: 50px;
    @media #{$md-layout} {
      margin-bottom: 10px;
    }

    p {
      width: 100%;
    }
  }

  .single-demo {
    text-align: center;
    margin-bottom: 80px;
    @media #{$sm-layout} {
      margin-bottom: 60px;
    }

    a {
      display: block;
    }

    .thumb {
      border-radius: 30px;
      display: block;
      margin-bottom: 40px;
      box-shadow: 0 78px 40px -58px rgba(153, 161, 170, 0.5);
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -75%;
        z-index: 2;
        display: block;
        width: 50%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
        transform: skewX(-25deg);
      }

      img {
        border-radius: 30px;
        transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
        width: 100%;
      }
    }

    .title {
      margin-bottom: 0;
      transition: var(--transition);
    }

    &:hover {
      .thumb {
        &:before {
          animation: shine .75s;
        }

        img {
          transform: scale(1.1);
        }
      }

      .title {
        color: var(--color-primary);
      }
    }
  }

  .shape-group {
    li {
      position: absolute;
      top: 25%;
      z-index: -1;

      &.shape-2 {
        top: 39%;
      }
    }
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

// Features
.splash-features {
  .section-heading {
    margin-bottom: 40px;
    @media #{$md-layout} {
      margin-bottom: 10px;
    }

    .title {
      width: 100%;
    }

    p {
      width: 100%;
    }
  }

  .services-grid {
    padding: 55px;
    height: 100%;
    margin-bottom: 0;
    display: block;

    .thumbnail {
      margin-bottom: 30px;
      margin-right: 0;
      max-width: 100%;
    }

    .content {
      .title {
        color: var(--color-white);
      }

      p {
        margin-bottom: 0;
        transition: var(--transition);
      }
    }

    &:hover, &.active {
      .content {
        p {
          opacity: 1;
          color: var(--color-light);
        }
      }
    }
  }
}

// Why Choose
.spalsh-why-choose {
  .section-heading {
    margin-bottom: 40px;
    @media #{$md-layout} {
      margin-bottom: 10px;
    }

    p {
      width: 100%;
    }
  }

  .line-shape {
    position: absolute;
    top: 90px;
    left: 0;
    transform: scaleX(-1);
    z-index: -1;
  }
}

.why-buy-box {
  background-color: var(--color-white);
  border-radius: 30px;
  padding: 35px;
  margin-bottom: 80px;
  border: var(--border-light);
  transition: var(--transition);
  @media #{$md-layout} {
    margin-bottom: 30px;
  }

  .heading {
    display: flex;
    align-content: center;

    .icon {
      text-align: center;
      height: 62px;
      width: 62px;
      line-height: 60px;
      background-color: var(--color-accent1);
      border-radius: 50%;
      margin-right: 20px;
    }

    .title {
      flex: 1;
      margin-bottom: 20px;
      transition: var(--transition);
    }
  }

  p {
    margin-bottom: 0;
    transition: var(--transition);
  }
}

.support-box {
  height: 100%;
  padding-bottom: 30px;

  a {
    display: block;
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: var(--color-white);
    border: var(--border-light);
    border-radius: 30px;
    overflow: hidden;
  }

  .inner {
    height: 100%;
    padding: 30px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    transition: var(--transition);
  }

  .heading {
    display: flex;
    align-content: center;

    .icon {
      text-align: center;
      height: 62px;
      width: 62px;
      line-height: 60px;
      background-color: var(--color-light);
      border-radius: 50%;
      margin-left: 20px;
    }

    .title {
      flex: 1;
      font-weight: 700;
      margin-bottom: 18px;
      letter-spacing: -0.045em;
      transition: var(--transition);
    }
  }

  p {
    color: var(--color-body);
    margin-bottom: 16px;
    transition: var(--transition);
  }

  .item-btn {
    text-align: center;
    height: 46px;
    width: 68px;
    line-height: 43px;
    font-size: 18px;
    border-radius: 100px;
    color: var(--color-white);
    border: none;
    transition: var(--transition);
  }

  .shape-group {
    li {
      position: absolute;
      right: 0;
      bottom: -1px;
      z-index: -1;
    }
  }

  &:hover, &.active {
    a {
      box-shadow: 0 50px 50px -24px rgba(153, 161, 170, 0.3);
    }

    .inner {
      .item-btn {
        background-color: var(--color-primary);
      }
    }
  }

  &.online-docuentation {
    .item-btn {
      background-color: var(--color-rose);
    }
  }

  &.support-ticket {
    .item-btn {
      background-color: var(--color-chart1);
    }
  }
}

// Call To action

.splash-call-to-action {
  background-color: var(--color-primary);
  padding: 140px 0 80px;
  margin-bottom: 0;
  @media #{$smlg-device} {
    padding: 100px 0 40px;
  }
  @media #{$md-layout} {
    padding: 80px 0 30px;
  }
  @media #{$sm-layout} {
    padding: 60px 0 10px;
  }

  &:before {
    display: none;
  }

  .section-heading {
    .title {
      margin-bottom: 20px;
      font-size: 80px;
      width: 100%;
      @media #{$laptop-device} {
        font-size: 70px;
      }
      @media #{$smlg-device} {
        font-size: 60px;
      }
      @media #{$sm-layout} {
        font-size: 50px;
      }
      @media #{$large-mobile} {
        font-size: 40px;
      }
    }

    p {
      width: 100%;
      font-size: 20px;
      color: var(--color-light);
      @media #{$md-layout} {
        font-size: 18px;
      }

      a {
        font-weight: 700;
        margin-left: 10px;
        color: var(--color-light);
        text-decoration: underline;

        &:hover {
          color: var(--color-white);
        }
      }
    }

    .axil-btn {
      color: #2A288B;
      padding: 24px 80px;
      filter: drop-shadow(0px 14px 20px rgba(35, 34, 87, 0.4)) drop-shadow(0px 48px 48px rgba(0, 0, 0, 0.12));
      box-shadow: none;
    }
  }

  .call-to-action {
    border-bottom: none;
  }
}

// Footer
.splash-footer {
  padding-top: 0 !important;

  .footer-bottom {
    border: none;
    padding: 30px 0;
  }

  .footer-copyright {
    @media #{$md-layout} {
      text-align: center;
      margin-bottom: 20px;
    }

    .copyright-text {
      font-size: 16px;
    }
  }

  .footer-social {
    text-align: center;
    @media #{$md-layout} {
      margin-bottom: 20px;
    }

    li {
      display: inline-block;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      a {
        height: 40px;
        width: 40px;
        line-height: 39px;
        text-align: center;
        background-color: var(--color-light);
        border-radius: 50%;
        color: var(--color-body);

        &:hover {
          background-color: var(--color-primary);
          color: var(--color-light);
        }
      }
    }
  }

  .footer-bottom-link {
    @media #{$md-layout} {
      text-align: center;
    }

    ul {
      margin: 0 -30px;
      @media #{$md-layout} {
        margin: 0 -20px;
      }

      li {
        padding: 0 30px;
        @media #{$md-layout} {
          padding: 0 20px;
        }

        &:after {
          height: 58%;
          width: 2px;
          right: -4px;
        }

        a {
          font-size: 16px;
          color: var(--color-body);
        }
      }
    }
  }
}