.resume-spacer-div {
    height: 100px;
}

.resume-header-bubbles {
    z-index: -1;
}

.service-scroll-navigation-area {
    padding-top: 0 !important;
}

/*BannerThree*/
.banner-custom {
    padding: 180px 0 80px !important;
}

/*Header Nav*/
.custom-header-logo {
    width: 70px !important;
}

.custom-mobile-header-logo {
    width: 80px !important;
}

/*--------------------------------------------------------------*/
/*AboutFour*/
.custom-nara-about-animated-title {
    height: 90px !important;
}

/*--------------------------------------------------------------*/

/*BreadCrumbResume*/
.download-resume-custom {
    padding: 8px 25px !important;
}

/*ScrollToTopButton*/
.scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
    width: 40px;
    height: 40px;
    color: white;
    background-color: var(--color-primary);
}

.scroll-button.hovered {
    opacity: 1; /* Adjust as needed for hover effect */
}

/*Resume Page*/
.breadcrum-area-custom {
    padding: 150px 0 25px 0 !important;
}

.list-unstyled-custom {
    margin: 10px 0 !important;

}

/*ServiceOne*/
.custom-section {
    padding: 60px 0 !important;
}

.custom-section-margin {
    margin-bottom: 60px;
}

/*ServiceProp*/
.thumbnail-img-custom {
    border-radius: 25px;
}

.title-custom {
    margin: 0 0 10px 0 !important;
}

.title-custom-color {
    color: #212121 !important;
}

.institute-name-custom {
    margin: 0 0 10px 0 !important;
}

.responsibilities-custom {
    -webkit-line-clamp: 50 !important;
    margin: 0 !important;
}

/*---------------------------------------------------------*/
/*PropOne*/
/* Container for the project grid */
.custom-nara-project-grid {
    position: relative;
    width: 100%;
    max-width: 800px; /* Adjusted max-width */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    margin: 0 auto; /* Center-align */
    overflow: hidden; /* Ensure content stays within bounds */
}

/* Thumbnail container with unified height */
.custom-nara-image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 16 / 9; /* Aspect ratio */
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-nara-image-container:hover {
    transform: scale(1.05);
}

/* Image within the tile */
.custom-nara-thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

/* Overlay for the image */
.custom-nara-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Dark overlay */
    transition: background-color 0.3s ease;
    z-index: 1;
    pointer-events: none; /* Make the overlay non-interactive */
}

.custom-nara-project-grid:hover .custom-nara-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

/* Content styling */
.custom-nara-content {
    width: 80%;
    padding: 10px;
    /*text-align: center;*/
    /*background-color: #ffffff;*/
    /*border-radius: 8px;*/
    margin-top: -20px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
}

/* Subtitle styling */
.custom-nara-subtitle {
    font-size: 0.8rem;
    color: #777;
    margin-bottom: 5px;
}

.custom-nara-subtitle-code {
    font-size: 0.8rem;
    color: #d63384;
    margin-bottom: 5px;
}

/* Hashtag style for categories */
.custom-nara-subtitle a {
    font-size: 0.8rem;
    color: #555;
    text-decoration: none;
    margin-right: 5px;
}

.custom-nara-subtitle a:hover {
    color: #0073e6;
}

/* Title styling */
.custom-nara-title {
    font-size: 2rem;
    color: #333;
    margin: 10px 0 0;
}

.custom-nara-title a {
    text-decoration: none;
    color: inherit;
}

.custom-nara-title a:hover {
    color: #0073e6;
}

/*---------------------------------------------*/
/* BlogListOne */
.custom-nara-small-blog-thumbnail-div {
    width: 300px; /* Default width for desktop */
    height: 240px; /* Fixed height for consistent appearance */
    overflow: hidden; /* Ensures content stays within bounds */
    position: relative; /* Allows image positioning */
    border-radius: 8px; /* Optional: rounded corners */
    margin: 0 auto; /* Center the div on smaller screens */

    @media (max-width: 768px) {
        width: 100%; /* Full width for mobile screens */
        height: 240px; /* Keep height consistent across all screen sizes */
    }
}

.custom-nara-small-blog-thumbnail-img {
    width: 100%; /* Fill the width of the div */
    height: 100%; /* Fill the height of the div */
    object-fit: cover; /* Ensures the image maintains its aspect ratio and covers the container */
    display: block; /* Prevents inline-block spacing issues */

    @media (max-width: 768px) {
        height: 240px; /* Maintain height for consistency across all screen sizes */
        object-fit: cover; /* Ensure it covers the div on smaller screens too */
    }
}

/*-----------------------------------------------*/
/*BlogGridOne*/
.custom-nara-author-thumb {
    width: 80px;
    height: 80px;
    border-radius: 25px;
}

.custom-nara-more-project-btn {
    margin-top: 20px !important;
}

/*-----------------------------------------------------------*/
/*ServicePropOne*/
.custom-nara-services-content {
    -webkit-line-clamp: 50 !important;
    margin: 0 !important;
}

.custom-nara-service-grid {
    min-height: 440px !important;
}

.custom-nara-service-title {
    margin: 0 !important;
}

/*-----------------------------------------------------------*/
/*LinkTreeComponent*/
.custom-wrapper-social {
    min-height: 100vh; /* Ensures the container spans the full viewport height */
    overflow-y: auto; /* Allows vertical scrolling if content exceeds screen height */
    display: flex; /* Optional: In case you need any flex behaviors */
    flex-direction: column; /* Optional: Keeps children stacked vertically */
}

.custom-linktree-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 60px 0;
}

.linktree-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.linktree-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: var(--color-primary, #0073e6);
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    transition: background-color 0.2s ease,
    transform 0.2s ease; /* Add a smooth transform transition */
}

.linktree-button:hover {
    background-color: var(--color-blue-shade, #005bb5);
    border: var(--border-lighter);
    transform: scale(1.05); /* Slightly enlarge on hover */
}


.linktree-thumbnail-img {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
    object-fit: cover;
    margin-right: 10px; /* Space between image and text */
    border-radius: 100%;
}

.linktree-text {
    font-size: 1rem;
    font-weight: 500;
}

.linktree-avatar-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.linktree-avatar-img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    object-fit: cover;
}

/*-----------------------------------*/
/*LoadingSpinner*/
.loading-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the bar and text horizontally */
    justify-content: center; /* If full-height, this can center vertically too */
    min-height: 100px; /* Ensure container has enough height for bar and text */
    padding: 20px;
}

/* The bar wrapper could have a background or border if you want a "track" */
.loading-bar {
    width: 200px; /* Adjust width as desired */
    height: 10px; /* The thickness of the bar */
    background-color: #ddd; /* A light background for the bar track */
    position: relative;
    overflow: hidden;
    border-radius: 5px; /* Slight rounding if desired */

    /* Create the inner bar for the moving effect */
}

.loading-bar::before {
    content: "";
    position: absolute;
    left: -50%; /* Start from off-screen to the left */
    width: 50%; /* The moving bar segment */
    height: 100%;
    background-color: var(--color-primary, #0073e6); /* The bar's moving color */
    animation: loadingBar 1s infinite ease-in-out alternate;
    border-radius: 5px; /* If you want the moving segment rounded too */
}

@keyframes loadingBar {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(200%); /* Travel past container width to the right */
    }
}

.loading-text {
    margin-top: 10px; /* Space between the bar and the text */
    font-size: 1rem;
    color: #333;
}

/*-----------------------------------*/
/*BlogSidebar*/
.custom-blog-social-icons {
    font-size: 1.2rem;
}

/*-----------------------------------*/
/*TopBanner*/
/* Fixed Top Banner */
.top-banner {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #0073e6;
    color: #fff;
    padding: 12px 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100; /* Ensures it's above the navbar */
}

.top-banner a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 8px;
}

.top-banner a:hover {
    color: #f1c40f;
}


/*-----------------------------------*/
/*BlogGridView*/
.blog-tags {
    margin: 16px 0; /* Space above tags */
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Space between tags */
}

.blog-tags .tag {
    font-size: 0.75rem;
    color: var(--color-primary, #0073e6);
    text-decoration: none;
    background: var(--color-light, #f4f4f4);
    padding: 4px 8px;
    /*border: 1px solid var(--color-primary, #0073e6);*/
    border-radius: 4px;
    transition: background 0.2s ease, color 0.2s ease;
}

.blog-tags .tag:hover {
    background: var(--color-primary, #0073e6);
    color: var(--color-light, #f4f4f4);
}

.custom-blog-description-p {
    margin: 0 0 25px 0 !important;
}
