/*-------------------------
    Forms Styles
-------------------------*/
input,
.form-control {
  border: var(--border-lighter);
  border-radius: 16px;
  background-color: transparent;
  color: var(--color-text-dark);
  padding: 15px 20px;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 20px 48px -14px rgba(153, 161, 170, 0.25);
    border-color: transparent;

  }

  /* -- Placeholder -- */
  &::placeholder {
    color: var(--color-gray-2);
    /* Firefox */
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-gray-2);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-gray-2);
  }
}

input {
  height: 60px;
}

.form-group {
  margin-bottom: 16px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dark);
    margin-bottom: 10px;
  }
}


// Input Group

.input-group {
  background-color: var(--color-white);
  border-radius: 20px;
  height: 72px;
  padding: 10px 10px 10px 55px;
  box-shadow: 0 24px 48px -15px rgba(153, 161, 170, .25);
  @media #{$small-mobile} {
    display: block;
    box-shadow: none;
    height: auto;
    padding: 0;
  }

  .mail-icon {
    position: absolute;
    left: 30px;
    top: 22px;
    z-index: 1;
    @media #{$small-mobile} {
      left: 15px;
      top: 16px;
    }
  }

  .form-control {
    padding: 5px 15px;
    height: auto;
    border: none;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }

    @media #{$small-mobile} {
      height: 60px;
      width: 100%;
      border-radius: 20px !important;
      padding: 5px 15px 5px 50px;
      border: 1px solid var(--color-light);
    }
  }

  .subscribe-btn {
    border: none;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 16px !important;
    margin-left: 0 !important;
    padding: 10px 28px;
    transition: var(--transition);

    &:hover {
      background-color: var(--color-link);
    }

    @media #{$small-mobile} {
      margin-top: 15px;
      padding: 14px 30px;
    }
  }
}

// Form Action Message

.error-msg, .success-msg {
  margin-top: 25px;

  p {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.error-msg {
  color: #ff0000;
}

.success-msg {
  color: #15c54b;
  margin-bottom: 0;
}





